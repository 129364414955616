import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import chroma from "chroma-js";
import "../App.css";

const LegendHistogram = ({
  data,
  propertyConfig,
  colorScale,
  xAxisLabel,
  min,
  max,
  bincount,
  selectedIndex,
}) => {
  const histogramRef = useRef();

  const formatValue = (value) => {
    if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
    if (value >= 1e3) return `${(value / 1e3).toFixed(1)}k`;
    return value.toFixed(0); // Show up to one decimal place
  };

  useEffect(() => {
    if (!propertyConfig || !colorScale) return;

    const propertyType = propertyConfig.type;
    d3.select(histogramRef.current).selectAll("*").remove();

    let processedData = [];
    const lowerThreshold = min - 10;
    const upperThreshold = max + 10;

    if (isNaN(min) || isNaN(max)) {
      console.error("Invalid min or max values", min, max);
      return;
    }

    if (propertyType === "number") {
      processedData = data.filter(
        (d) => d !== null && !isNaN(d) && d >= lowerThreshold && d <= upperThreshold
      );
    } else if (propertyType === "array") {
      processedData = data
        .map((d) => (Array.isArray(d) ? d[selectedIndex] : null))
        .filter(
          (d) => d !== null && !isNaN(d) && d >= lowerThreshold && d <= upperThreshold
        );
    } else if (propertyType === "category") {
      processedData = data.filter((d) => d !== null && d !== undefined);
    } else {
      console.error("Unsupported property type:", propertyType);
      return;
    }

    const margin = { top: 20, right: 10, bottom: 70, left: 20 };
    const width = 350 - margin.left - margin.right;
    const height = 200 - margin.top - margin.bottom;

    const svg = d3
      .select(histogramRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const chromaScale = chroma.scale(colorScale || ["#000", "#fff"]).mode("lab");

    if (propertyType === "number" || propertyType === "array") {
      const x = d3.scaleLinear().domain([min, max]).range([0, width]);

      // Explicitly calculate thresholds for bins
      const binGenerator = d3
        .bin()
        .domain(x.domain())
        .thresholds(
          Array.from({ length: bincount + 1 }, (_, i) =>
            min + (i * (max - min)) / bincount
          )
        );

      const bins = binGenerator(processedData);

      const yMax = d3.max(bins, (d) => d.length);
      const y = d3.scaleLinear().domain([0, yMax]).range([height, 0]);

      // Render the x-axis with dynamic bin labels
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(
          d3.axisBottom(x)
            .tickValues(
              Array.from({ length: bincount + 1 }, (_, i) =>
                min + (i * (max - min)) / bincount
              )
            )
            .tickFormat((d) => formatValue(d))
        )
        .selectAll("text")
        .attr("transform", "rotate(45)")
        .attr("x", 10)
        .attr("y", 10)
        .style("text-anchor", "start");

      // x-axis label
      svg.append("text")
        .attr("transform", `translate(${width / 2},${height + margin.bottom})`)
        .style("text-anchor", "middle")
        .style("font-size", "12px")
        .text(xAxisLabel);

      // Render bars only for bins with data
      svg.selectAll(".bar")
        .data(bins.filter((d) => d.length > 0))
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => x(d.x0))
        .attr("y", (d) => y(d.length))
        .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
        .attr("height", (d) => height - y(d.length))
        .attr("fill", (d) => chromaScale((d.x0 - min) / (max - min)).hex());

      // Add counts on top of bars
      svg.selectAll(".bar-label")
        .data(bins.filter((d) => d.length > 0))
        .enter()
        .append("text")
        .attr("class", "bar-label")
        .attr("x", (d) => x(d.x0) + (x(d.x1) - x(d.x0)) / 2) // Center of the bin
        .attr("y", (d) => y(d.length) - 5) // Above the bar
        .attr("text-anchor", "middle")
        .attr("font-size", "10px")
        .attr("fill", "#808080") // Grey for counts
        .text((d) => formatValue(d.length)); // Format large numbers

      // Render color legend blocks underneath all bins
      svg.selectAll(".legend-block")
        .data(bins)
        .enter()
        .append("rect")
        .attr("class", "legend-block")
        .attr("x", (d) => x(d.x0))
        .attr("y", height + 5) // Slightly below the bars
        .attr("width", (d) => x(d.x1) - x(d.x0) - 1)
        .attr("height", 4) // Height for the legend block
        .attr("fill", (d) => chromaScale((d.x0 - min) / (max - min)).hex());
    }
  }, [data, propertyConfig, colorScale, xAxisLabel, min, max, bincount, selectedIndex]);

  return <div ref={histogramRef} className="chart-area"></div>;
};

export default LegendHistogram;
