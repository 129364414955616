import * as d3 from 'd3';

export const renderEnergyFuelUseGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!Array.isArray(selectedFeatures) || selectedFeatures.length === 0) {
    console.error("Invalid or empty selectedFeatures:", selectedFeatures);
    return;
  }

  const formatNumberWithCommas = d3.format(',');
  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature.geometry?.type !== 'Point'
  );


  const gasData = Array(12).fill(0);
  const electricityData = Array(12).fill(0);
  const pvData = Array(12).fill(0);

  filteredFeatures.forEach((feature) => {
    const gas = Array.isArray(feature.properties?.["Gas[kWh][Mth]"]) ? feature.properties["Gas[kWh][Mth]"] : [];
    const electricity = Array.isArray(feature.properties?.["Electricity[kWh][Mth]"]) ? feature.properties["Electricity[kWh][Mth]"] : [];
    const pv = Array.isArray(feature.properties?.["PV[KWh][Mth]"]) ? feature.properties["PV[KWh][Mth]"] : [];

    for (let month = 0; month < 12; month++) {
      if (gas[month] !== undefined) gasData[month] += gas[month] || 0;
      if (electricity[month] !== undefined) electricityData[month] += electricity[month] || 0;
      if (pv[month] !== undefined) pvData[month] += pv[month] || 0;
    }
  });

  if (gasData.every(d => d === 0) && electricityData.every(d => d === 0) && pvData.every(d => d === 0)) {
    console.warn("No data available to render the graph.");
    return;
  }

  const margin = { top: 20, right: 30, bottom: 70, left: 50 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = dashboardConfig?.chartSettings?.height || 250 - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleLinear()
    .domain([0, 11])
    .range([0, width]);

  const y = d3.scaleLinear()
    .domain([0, d3.max([...gasData, ...electricityData, ...pvData]) || 0])
    .nice()
    .range([height, 0]);

  const lineGenerator = d3.line()
    .x((d, i) => x(i))
    .y(d => y(d));

  // Tooltip container
  const tooltip = d3.select('body').append('div')
    .style('position', 'absolute')
    .style('background', 'white')
    .style('padding', '5px 10px')
    .style('border', '1px solid #ccc')
    .style('border-radius', '5px')
    .style('pointer-events', 'none')
    .style('opacity', 0);

  const formatInteger = d3.format('d');  // Formatter for integer values

  // Line paths for each energy type
  svg.append('path')
    .datum(electricityData)
    .attr('fill', 'none')
    .attr('stroke', '#1569C7')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  svg.append('path')
    .datum(gasData)
    .attr('fill', 'none')
    .attr('stroke', '#FFB100')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  svg.append('path')
    .datum(pvData)
    .attr('fill', 'none')
    .attr('stroke', '#800080') // Purple color for PV
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  // Add circles at each data point and show tooltip on hover
  const addCirclesWithTooltip = (data, color, label) => {
    svg.selectAll(`circle.${label}`)
      .data(data)
      .enter()
      .append('circle')
      .attr('class', label)
      .attr('cx', (d, i) => x(i))
      .attr('cy', d => y(d))
      .attr('r', 4)
      .attr('fill', color)
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip.html(`${label}: ${formatNumberWithCommas(d)} kWh`)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 20) + 'px');
      })
      
      .on('mousemove', (event) => {
        tooltip.style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 20) + 'px');
      })
      .on('mouseout', () => {
        tooltip.transition().duration(500).style('opacity', 0);
      });
  };

  // Adding circles and tooltips for each line
  addCirclesWithTooltip(electricityData, '#1569C7', 'Electricity');
  addCirclesWithTooltip(gasData, '#FFB100', 'Gas');
  addCirclesWithTooltip(pvData, '#800080', 'PV');

  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).ticks(12).tickFormat(i => `${i + 1}`));

  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // Y-axis label
  svg.append("text")
    .attr("class", "y-axis-label")
    .attr("x", -(height / 2))
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .attr("transform", "rotate(-90)")
    .style("font-size", "10px")
    .text("kWh");

  // X-axis label
  svg.append("text")
    .attr("class", "x-axis-label")
    .attr("x", width+20)
    .attr("y", height + 20)
    .attr("text-anchor", "middle")
    .style("font-size", "10px")
    .text("Mth");

  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 30})`);

  legend.append('rect')
    .attr('x', 0)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#1569C7');

  legend.append('text')
    .attr('x', 20)
    .attr('y', 5)
    .text('Electricity')
    .style('font-size', '10px')
    .style('fill', '#000');

  legend.append('rect')
    .attr('x', 100)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#FFB100');

  legend.append('text')
    .attr('x', 120)
    .attr('y', 5)
    .text('Gas')
    .style('font-size', '10px')
    .style('fill', '#000');

  legend.append('rect')
    .attr('x', 200)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#800080'); // Purple color for PV

  legend.append('text')
    .attr('x', 220)
    .attr('y', 5)
    .text('PV')
    .style('font-size', '10px')
    .style('fill', '#000');
};
