import React, { useState, useRef } from "react";
import { FileUploadLogic, calculatePropertySums } from "./FileUploadLogic";
import {
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Overlay from "./Overlay";
import * as utilities from "./Utilities";

// Helper function to truncate long filenames for display
const truncateFilename = (filename, maxLength = 40) => {
  if (filename.length <= maxLength) return filename;
  const start = filename.substring(0, Math.floor(maxLength / 2));
  const end = filename.substring(filename.length - Math.floor(maxLength / 2));
  return `${start}...${end}`;
};

function FileUploadCard({
  showFileUploadCard,
  isAppInitialized,
  setIsAppInitialized,
  fileList,
  setFileList,
  setGeoJsonFilesByDashboard,
  setInitialSelection,
  onClose,
  config,
  setConfig,
  setScenarioData,
  canClose,
  setCanClose,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [removedFiles, setRemovedFiles] = useState([]);
  const fileInputElement = useRef(null);

  // Handle click event on the close button
  const handleCloseClick = () => {
    setFileList((prevFileList) => [...prevFileList, ...removedFiles]);
    setRemovedFiles([]);
    onClose();
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setShowSnackbar(true);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    handleFileProcessing(files);
  };

  const handleFileProcessing = async (files) => {
    const validFiles = [];
    const invalidFiles = [];
    const duplicateFiles = [];
  
    for (const file of files) {
      if (file.name.endsWith(".geojson")) {
        if (fileList.some((f) => f.name === file.name)) {
          duplicateFiles.push(file.name);
        } else if (file.size > 75000000000) {
          invalidFiles.push(`${file.name} (File too large)`);
        } else {
          validFiles.push(file);
        }
      } else if (file.name.endsWith(".csv")) {
        try {
          const geoJson = await utilities.convertCsvToGeoJson(file);
          const geoJsonFile = new File(
            [JSON.stringify(geoJson)],
            `${file.name.replace(".csv", ".geojson")}`,
            { type: "application/geo+json" }
          );
          if (fileList.some((f) => f.name === geoJsonFile.name)) {
            duplicateFiles.push(file.name);
          } else {
            validFiles.push(geoJsonFile);
          }
        } catch (error) {
          invalidFiles.push(`${file.name} (${error})`);
        }
      } else {
        invalidFiles.push(`${file.name} (Unsupported file type)`);
      }
    }
  
    if (invalidFiles.length > 0) {
      handleError(
        `The following files are invalid:\n${invalidFiles.join("\n")}`
      );
    }
  
    if (duplicateFiles.length > 0) {
      handleError(
        `The following files are duplicates and were not added:\n${duplicateFiles.join("\n")}`
      );
    }
  
    if (validFiles.length > 0) {
      setFileList((prevFileList) => [...prevFileList, ...validFiles]);
      setIsAppInitialized(true);
    }
  };
  
  

  const handleUploadButtonClick = () => {
    if (fileInputElement.current) {
      fileInputElement.current.click();
    } else {
      console.error("File input element is not set.");
    }
  };

  const handleViewClick = async () => {
    if (fileList.length === 0 && removedFiles.length > 0) {
      setFileList(removedFiles);
      setRemovedFiles([]);
      return;
    }

    setIsLoading(true);
    try {
      const dashboards = await FileUploadLogic(
        fileList,
        config,
        setConfig,
        setFileProgress
      );
      setGeoJsonFilesByDashboard(dashboards);

      const firstDashboard = Object.keys(dashboards)[0];
      const firstScenario = dashboards[firstDashboard][0].data;

      const initialSelectedProperty =
        config[firstDashboard]?.selectedProperty ||
        config["DataDashboard"]?.selectedProperty ||
        config["DataDashboard"]?.properties[0]?.name;

      setInitialSelection({
        dashboard: firstDashboard,
        scenario: firstScenario,
        selectedProperty: initialSelectedProperty,
      });

      const scenarioSums = utilities.calculatePropertySums(dashboards);
      setScenarioData(scenarioSums);

      setCanClose(true);
      onClose();
      setRemovedFiles([]);
    } catch (error) {
      handleError(`Error processing files: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = (index) => {
    const removedFile = fileList[index];
    setRemovedFiles((prevRemovedFiles) => [...prevRemovedFiles, removedFile]);

    const newFileList = fileList.filter((_, i) => i !== index);
    setFileList(newFileList);

    if (newFileList.length === 0) {
      if (canClose) {
        setErrorMessage("Please upload files to edit file list.");
        setShowSnackbar(true);
        return;
      } else {
        setIsAppInitialized(false);
      }
      setCanClose(false);
      return;
    }

    setGeoJsonFilesByDashboard((prevGeoJsonFiles) => {
      const newGeoJsonFiles = { ...prevGeoJsonFiles };
      for (const dashboard in newGeoJsonFiles) {
        newGeoJsonFiles[dashboard] = newGeoJsonFiles[dashboard].filter(
          (fileData) => fileData.name !== removedFile.name
        );

        if (newGeoJsonFiles[dashboard].length === 0) {
          delete newGeoJsonFiles[dashboard];
        }
      }
      return newGeoJsonFiles;
    });

    setScenarioData((prevScenarioData) => {
      const newScenarioData = { ...prevScenarioData };
      for (const scenario in newScenarioData) {
        if (scenario.includes(removedFile.name)) {
          delete newScenarioData[scenario];
        }
      }
      return newScenarioData;
    });
  };

  // Load all demo data from the demo folder
  const loadDemoData = async () => {
    // Define the list of filenames in the demo data folder
    const demoFiles = [
      "102_Greenwich Village, Soho.geojson",
      // Add more filenames as needed
    ];

    setIsLoading(true);
    try {
      const fetchedFiles = await Promise.all(
        demoFiles.map(async (filename) => {
          const response = await fetch(
            `${process.env.PUBLIC_URL}/demoData/${filename}`
          );
          if (!response.ok) {
            throw new Error(`Failed to load demo data: ${filename}`);
          }
          const demoBlob = await response.blob();
          return new File([demoBlob], filename, {
            type: "application/geo+json",
          });
        })
      );

      // Process the loaded files just like user-uploaded files
      handleFileProcessing(fetchedFiles);
    } catch (error) {
      handleError(`Error loading demo data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* Snackbar to display error messages */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Display initial upload UI if app is not initialized */}
      {!isAppInitialized ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            zIndex: 1000,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/Urbano-Viewer-Logo.png`}
            alt="Urbano Viewer"
            style={{
              width: "500px",
              height: "auto",
              padding: "10px 10px",
            }}
          />
          <Box margin={2}>
            <input
              accept=".csv,.geojson,application/json"
              style={{ display: "none" }}
              id="upload-file"
              type="file"
              multiple
              onChange={handleFileUpload}
              ref={fileInputElement}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadButtonClick}
              disabled={isLoading}
            >
              Upload
            </Button>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={loadDemoData} // Load all demo data on click
              disabled={isLoading}
              sx={{ margin: 2 }}
            >
              Demo Data
            </Button> */}
          </Box>
      {/* Demo data link styled as clickable text */}
      <h5
        onClick={loadDemoData}
        style={{
          color: "grey",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        preview demo data
      </h5>
      <h5 style={{ color: "grey" }}>made in ithaca, ny with ❤️</h5>
      <h5 style={{ color: "grey" }}>V 1.9</h5>
        </div>
      ) : (
        showFileUploadCard && (
          <div>
            <Overlay />
            <Paper
              elevation={3}
              style={{
                marginTop: "20px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "500px",
                height: "600px",
                zIndex: 1000,
              }}
            >
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5" align="left">
                    File Upload
                  </Typography>
                  {canClose && (
                    <IconButton onClick={handleCloseClick}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>

                <Typography
                  variant="body1"
                  align="left"
                  sx={{ marginTop: "10px" }}
                >
                  Manage GeoJSON files here
                </Typography>

                <Box
                  sx={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    marginTop: "10px",
                  }}
                >
                  <List>
                    {fileList.map((file, index) => (
                      <ListItem
                        key={index}
                        sx={{ display: "block", paddingY: 1 }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveFile(index)}
                            disabled={isLoading}
                          >
                            <CloseIcon />
                          </IconButton>
                          <ListItemText
                            primary={utilities.truncateFileName(file.name)}
                          />
                        </Box>
                        <Box sx={{ width: "100%", marginTop: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={fileProgress[file.name] || 0}
                            sx={{ bgcolor: "lightgrey" }}
                          />
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mt={2}
              >
                <input
                  accept=".geojson,application/json"
                  style={{ display: "none" }}
                  id="upload-file"
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  ref={fileInputElement}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadButtonClick}
                  disabled={isLoading}
                  sx={{ mr: 2 }}
                >
                  Upload
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleViewClick}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "OK"
                  )}
                </Button>
              </Box>
            </Paper>
          </div>
        )
      )}
    </div>
  );
}

export default FileUploadCard;
