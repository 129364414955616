import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import HighlightAlt from "@mui/icons-material/HighlightAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import FileUploadCard from "./components/FileUploadCard";
import ScenarioCard from "./components/ScenarioCard";
import LegendCard from "./components/LegendCard";
import Map from "./components/Map";
import mapboxgl from "mapbox-gl";
import ChartsCard from "./components/ChartsCard";
import TimeSliderCard from "./components/TimeSliderCard";
import dashboardConfigData from "./components/DashboardConfig";
import DashboardConfigEditor from "./components/DashboardConfigEditor";
import RoadSurfaceToggle from "./components/RoadSurfaceToggle";
import SearchBar from "./components/SearchBar";
import { debounce } from "lodash";
import { Box } from "@mui/material";

// Create a Material-UI theme to apply consistent styling across the app
const theme = createTheme();

function App() {
  const [isGeocoderAdded, setIsGeocoderAdded] = useState(false); // Flag to track if geocoder is added

  // State to track if the app has been initialized
  const [isAppInitialized, setIsAppInitialized] = useState(false);

  // State to manage whether certain UI components can be closed
  const [canClose, setCanClose] = useState(false);

  // State to manage the dashboard configuration
  const [config, setConfig] = useState(dashboardConfigData);

  // State to store data for the selected scenario
  const [scenarioData, setScenarioData] = useState({});

  // State to manage GeoJSON files grouped by dashboards
  const [geoJsonFilesByDashboard, setGeoJsonFilesByDashboard] = useState({});

  // State for the currently selected GeoJSON file
  const [selectedGeoJson, setSelectedGeoJson] = useState(null);

  // State to track selected features on the map
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  // State to control the visibility of the FileUploadCard
  const [showFileUploadCard, setShowFileUploadCard] = useState(true);

  // State to control the visibility of the DashboardConfigEditor
  const [showDashboardConfigCard, setShowDashboardConfigCard] = useState(false);

  // State to store the list of uploaded files
  const [fileList, setFileList] = useState([]);

  // State to track the selected dashboard, defaulting to the first config or "DataDashboard"
  const [selectedDashboard, setSelectedDashboard] = useState(
    Object.keys(config)[0] || "DataDashboard"
  );

  // State to track the selected property for the currently active dashboard
  const [selectedProperty, setSelectedProperty] = useState(
    config[selectedDashboard]?.selectedProperty || ""
  );

  // State to track the current scenario
  const [currentScenario, setCurrentScenario] = useState(null);

  // State to manage the selected index for scenarios or other indexed data
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Reference to the map object for interaction between components
  const mapRef = useRef(null);

  // State to manage the legend's minimum and maximum values
  const [legendMin, setLegendMin] = useState(0);
  const [legendMax, setLegendMax] = useState(100);
  const [colorScale, setColorScale] = useState("YlGnBu");

  // New state variables for color pickers
  const [belowMinColor, setBelowMinColor] = useState("#808080"); // Grey
  const [aboveMaxColor, setAboveMaxColor] = useState("#FFC0CB"); // Pink

  const handleZoomToData = () => {
    const bounds = new mapboxgl.LngLatBounds();
    if (selectedGeoJson && selectedGeoJson.features) {
      selectedGeoJson.features.forEach((feature) => {
        if (feature.geometry.type === "Point") {
          bounds.extend(feature.geometry.coordinates);
        } else if (
          ["LineString", "MultiPoint"].includes(feature.geometry.type)
        ) {
          feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
        } else if (
          ["Polygon", "MultiLineString"].includes(feature.geometry.type)
        ) {
          feature.geometry.coordinates.forEach((ring) =>
            ring.forEach((coord) => bounds.extend(coord))
          );
        } else if (feature.geometry.type === "MultiPolygon") {
          feature.geometry.coordinates.forEach((polygon) =>
            polygon.forEach((ring) =>
              ring.forEach((coord) => bounds.extend(coord))
            )
          );
        }
      });
      mapRef.current.fitBounds(bounds, { padding: 20 });
    }
  };

  // Callback to handle saving updated configurations
  const handleSaveConfig = useCallback((updatedConfig) => {
    setConfig(updatedConfig);
  }, []);

  // Debounced callback to handle scenario selection based on GeoJSON data
  const handleScenarioSelect = useCallback(
    debounce((geoJson) => {
      if (geoJson && geoJson.features) {
        setSelectedGeoJson(geoJson);
        setCurrentScenario(geoJson);
      } else {
        console.error(
          "Invalid GeoJSON passed to handleScenarioSelect:",
          geoJson
        );
        setSelectedGeoJson(null);
        setCurrentScenario(null);
      }
    }, 300),
    []
  );

  // Callback to set the initial selection when switching between dashboards, scenarios, etc.
  const setInitialSelection = useCallback(
    ({ dashboard, scenario, selectedProperty }) => {
      setSelectedDashboard(dashboard);
      setSelectedGeoJson(scenario);
      setCurrentScenario(scenario);
      setSelectedProperty(
        selectedProperty || config[dashboard].selectedProperty
      );
      setSelectedFeatures(scenario.features);
    },
    [config]
  );

  // Memoized value for the current configuration, depending on the selected dashboard
  const currentConfig = useMemo(
    () => config[selectedDashboard] || {},
    [selectedDashboard, config]
  );

  // Callback to handle slider changes, such as in TimeSliderCard or other indexed components
  const handleSliderChange = useCallback((newIndex) => {
    setSelectedIndex(newIndex);
    // Additional logic for updating the map and charts based on the selected index
  }, []);

  useEffect(() => {
    // Show the DashboardConfigEditor initially when the app first runs
    setShowDashboardConfigCard(true);


    // After a short delay, hide the DashboardConfigEditor
    const timer = setTimeout(() => {
      setShowDashboardConfigCard(false);
    }, 1); // Adjust the delay if necessary

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
        {/* Map component to display the selected GeoJSON data */}
        <Map
          geoJsonData={selectedGeoJson}
          selectedProperty={selectedProperty}
          mapRef={mapRef}
          selectedDashboard={selectedDashboard}
          setSelectedFeatures={setSelectedFeatures}
          selectedFeatures={selectedFeatures}
          currentScenario={currentScenario}
          config={config}
          legendMax={legendMax}
          legendMin={legendMin}
          selectedIndex={selectedIndex}
          colorScale={colorScale}
          belowMinColor={belowMinColor}
          aboveMaxColor={aboveMaxColor}
        />

        {/* Icon button to toggle the dashboard config editor */}
        <Paper
          elevation={3}
          style={{
            padding: "5px",
            position: "absolute",
            top: "20px",
            right: "20px",
            width: "400px",
            zIndex: 1,
          }}
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <SearchBar
              mapRef={mapRef}
              isGeocoderAdded={isGeocoderAdded}
              setIsGeocoderAdded={setIsGeocoderAdded}
            />
            <RoadSurfaceToggle mapRef={mapRef} /> 
            <IconButton color="primary" onClick={handleZoomToData}>
              <ZoomInIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => setShowDashboardConfigCard((prev) => !prev)}
            >
              <SettingsIcon />
            </IconButton>
          </Box>

        </Paper>

        {/* File upload card for handling GeoJSON uploads */}
        {showFileUploadCard && (
          <FileUploadCard
            showFileUploadCard={showFileUploadCard}
            isAppInitialized={isAppInitialized}
            setIsAppInitialized={setIsAppInitialized}
            fileList={fileList}
            setFileList={setFileList}
            setGeoJsonFilesByDashboard={setGeoJsonFilesByDashboard}
            setInitialSelection={setInitialSelection}
            onClose={() => setShowFileUploadCard(false)}
            config={config}
            setConfig={setConfig}
            setScenarioData={setScenarioData}
            canClose={canClose}
            setCanClose={setCanClose}
          />
        )}

        {/* Dashboard config editor for adjusting the settings */}
        {showDashboardConfigCard && (
          <DashboardConfigEditor
            defaultConfig={config}
            onSave={handleSaveConfig}
            config={config}
            onClose={() => setShowDashboardConfigCard(false)}
          />
        )}

        {/* Scenario card to switch between different scenarios */}
        {Object.keys(geoJsonFilesByDashboard).length > 0 && (
          <ScenarioCard
            geoJsonFilesByDashboard={geoJsonFilesByDashboard}
            setSelectedGeoJson={handleScenarioSelect}
            selectedGeoJson={selectedGeoJson}
            onOpenFileUploadCard={() => setShowFileUploadCard(true)}
            setSelectedDashboard={setSelectedDashboard}
            selectedDashboard={selectedDashboard}
            scenarioData={scenarioData}
            setScenarioData={setScenarioData}
            config={config}
          />
        )}

        {/* LegendCard and ChartsCard only render when a GeoJSON scenario is selected */}
        {selectedGeoJson && (
          <>
            <LegendCard
              selectedDashboard={selectedDashboard}
              selectedGeoJson={selectedGeoJson}
              selectedProperty={selectedProperty}
              setSelectedProperty={setSelectedProperty}
              mapRef={mapRef}
              config={config}
              setConfig={setConfig}
              setLegendMin={setLegendMin}
              setLegendMax={setLegendMax}
              legendMin={legendMin}
              legendMax={legendMax}
              selectedIndex={selectedIndex}
              colorScale={colorScale}
              setColorScale={setColorScale}
              belowMinColor={belowMinColor}
              setBelowMinColor={setBelowMinColor}
              aboveMaxColor={aboveMaxColor}
              setAboveMaxColor={setAboveMaxColor}
            />

            <ChartsCard
              selectedDashboard={selectedDashboard}
              config={config}
              selectedFeatures={selectedFeatures}
            />

            <TimeSliderCard
              selectedGeoJson={selectedGeoJson}
              selectedDashboard={selectedDashboard}
              selectedProperty={selectedProperty}
              config={config}
              onSliderChange={handleSliderChange}
              selectedIndex={selectedIndex}
            />
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
