import * as d3 from 'd3';

export const renderCO2SummaryGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  // Ensure selectedFeatures is an array and contains valid elements
  if (!Array.isArray(selectedFeatures) || selectedFeatures.length === 0) {
    console.error("Invalid or empty selectedFeatures:", selectedFeatures);
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature.geometry?.type !== 'Point'
  );

  const monthlyData = Array.from({ length: 12 }, () => ({
    CO2_Gas: 0,
    CO2_Electricity: 0,
  }));

  filteredFeatures.forEach((feature) => {
    const CO2_Gas = Array.isArray(feature.properties?.["CO2_Gas[kg][Mth]"]) ? feature.properties["CO2_Gas[kg][Mth]"] : [];
    const CO2_Electricity = Array.isArray(feature.properties?.["CO2_Electricity[kg][Mth]"]) ? feature.properties["CO2_Electricity[kg][Mth]"] : [];

    for (let month = 0; month < 12; month++) {
      if (CO2_Gas[month] !== undefined) monthlyData[month].CO2_Gas += CO2_Gas[month] || 0;
      if (CO2_Electricity[month] !== undefined) monthlyData[month].CO2_Electricity += CO2_Electricity[month] || 0;
    }
  });

  const keys = Object.keys(monthlyData[0]).filter(key => {
    return monthlyData.some(month => month[key] > 0);
  });

  if (keys.length === 0) {
    console.error("No valid keys found in monthlyData:", monthlyData);
    return;
  }

  const margin = { top: 0, right: 30, bottom: 60, left: 50 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = dashboardConfig?.chartSettings?.height || 250 - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleBand()
    .domain(d3.range(12))
    .range([0, width])
    .padding(0.1);

  const y = d3.scaleLinear()
    .domain([0, d3.max(monthlyData, d => keys.reduce((sum, key) => sum + d[key], 0)) || 0])
    .nice()
    .range([height, 0]);

  const colorScale = d3.scaleOrdinal()
    .domain(['CO2_Gas', 'CO2_Electricity'])
    .range(['#FFB100', '#1569C7']);

  svg.selectAll('g.layer')
    .data(d3.stack().keys(keys)(monthlyData))
    .enter().append('g')
    .attr('class', 'layer')
    .attr('fill', d => colorScale(d.key))
    .selectAll('rect')
    .data(d => d)
    .enter().append('rect')
    .attr('x', (d, i) => x(i))
    .attr('y', d => y(d[1]))
    .attr('height', d => y(d[0]) - y(d[1]))
    .attr('width', x.bandwidth());

  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).tickFormat(i => ` ${i + 1}`));

  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // Y-axis label
  svg.append("text")
    .attr("class", "y-axis-label")
    .attr("x", -(height / 2))
    .attr("y", -margin.left + 15)
    .attr("text-anchor", "middle")
    .attr("transform", "rotate(-90)")
    .style("font-size", "10px")
    .text("kWh");

    // X-axis label
svg.append("text")
.attr("class", "x-axis-label")
.attr("x", width+10)
.attr("y", height + 20)
.attr("text-anchor", "middle")
.style("font-size", "10px")
.text("Mth");


  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 30})`);

  const categories = ['CO2_Gas', 'CO2_Electricity'];
  const legendXOffset = 20;
  const legendYOffset = 10;
  
  categories.forEach((category, i) => {
    const row = Math.floor(i / 2);
    const col = i % 2;
  
    legend.append('rect')
      .attr('x', col * 100)
      .attr('y', row * 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(category));
  
    legend.append('text')
      .attr('x', col * 100 + legendXOffset)
      .attr('y', row * 20 + legendYOffset)
      .text(category)
      .style('font-size', '12px')
      .style('fill', '#000');
  });
};
