import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Tooltip from "./Tooltip";
import { createPortal } from "react-dom";
import ColorCodingLogic, { updateMapColors } from "./ColorCodingLogic";
import MapSelectionLogic from "./MapSelectionLogic";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import "../App.css";

// Set Mapbox access token from environment variable for secure access
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function Map({
  geoJsonData,
  selectedProperty,
  mapRef,
  setSelectedFeatures,
  selectedFeatures,
  config,
  selectedDashboard,
  currentScenario,
  legendMax,
  legendMin,
  selectedIndex,
  colorScale,
  belowMinColor,
  aboveMaxColor,
}) {
  // Reference to map container div
  const mapContainer = useRef(null);
  // State for managing tooltip position and content
  const [tooltip, setTooltip] = useState({
    x: 0,
    y: 0,
    propertyValues: [],
  });
  // State to check if the map is loaded
  const [mapLoaded, setMapLoaded] = useState(false);
  // State to track if the first layer has been loaded
  const [firstLayerLoaded, setFirstLayerLoaded] = useState(false);
  // State to control the display of the snackbar
  const [showSnackbar, setShowSnackbar] = useState(false);
  // State to control whether road surface layers are on top
  const [roadSurfaceOnTop, setRoadSurfaceOnTop] = useState(false);

  // Function to initialize the Mapbox map
  const initializeMap = () => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current, // Reference to map container
      style: "mapbox://styles/kx64/cljdd8xr6003l01qkbxctho6q", // Map style
      center: [0, 0], // Initial center of the map
      zoom: 2, // Initial zoom level
      attributionControl: false, // Disable default attribution control
      pitchWithRotate: false, // Disable entering 3D mode with right click
      dragRotate: false // Optional: Disable rotating the map with right click
    });

    // Add compact attribution control to the bottom-right corner
    mapRef.current.addControl(
      new mapboxgl.AttributionControl({ compact: true }),
      "bottom-right"
    );

    // Set mapLoaded state to true once the map is fully loaded
    mapRef.current.on("load", () => setMapLoaded(true));
  };

  // useEffect to initialize the map only once when the component mounts
  useEffect(() => {
    if (!mapRef.current) {
      initializeMap(); // Call initializeMap if the mapRef is not already set
    }
  }, [mapRef]);


  // useEffect to handle updating the map when geoJsonData or selectedFeatures change
  useEffect(() => {
    // Exit if mapRef or geoJsonData is not available
    if (!mapRef.current || !geoJsonData) return;

    const updateMap = () => {
      const source = mapRef.current.getSource("geojson-source");
      // Use selected features if available, otherwise use all features
      const featuresToShow = selectedFeatures.length
        ? selectedFeatures
        : geoJsonData.features;

      if (source) {
        // Update existing source data if the source already exists
        source.setData({
          type: "FeatureCollection",
          features: featuresToShow,
        });
      } else {
        // Add new GeoJSON source if it doesn't exist
        mapRef.current.addSource("geojson-source", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: featuresToShow,
          },
        });

        // Add fill layer for polygons and multipolygons
        mapRef.current.addLayer(
          {
            id: "geojson-fill-layer",
            type: "fill",
            source: "geojson-source",
            filter: [
              "any",
              ["==", ["geometry-type"], "Polygon"],
              ["==", ["geometry-type"], "MultiPolygon"],
            ],
            paint: {
              "fill-color": "#D3D3D3",
              "fill-opacity": 0.7,
            },
          },
        );

        // Add line layer for line strings and multilines
        mapRef.current.addLayer(
          {
            id: "geojson-line-layer",
            type: "line",
            source: "geojson-source",
            filter: [
              "any",
              ["==", ["geometry-type"], "LineString"],
              ["==", ["geometry-type"], "MultiLineString"],
            ],
            paint: {
              "line-color": "#D3D3D3",
              "line-width": 2,
            },
          },
        );
      }




      // Calculate the bounds of the features to fit the map
      const bounds = new mapboxgl.LngLatBounds();
      featuresToShow.forEach((feature) => {
        if (feature.geometry.type === "Point") {
          bounds.extend(feature.geometry.coordinates);
        } else if (["LineString", "MultiPoint"].includes(feature.geometry.type)) {
          feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
        } else if (["Polygon", "MultiLineString"].includes(feature.geometry.type)) {
          feature.geometry.coordinates.forEach((ring) =>
            ring.forEach((coord) => bounds.extend(coord))
          );
        } else if (feature.geometry.type === "MultiPolygon") {
          feature.geometry.coordinates.forEach((polygon) =>
            polygon.forEach((ring) =>
              ring.forEach((coord) => bounds.extend(coord))
            )
          );
        }
      });

      if (!firstLayerLoaded) {
        // On first layer load, fit map to data bounds
        mapRef.current.fitBounds(bounds);
        setFirstLayerLoaded(true); // Mark first layer as loaded
        let trigger = "initialLoad";
        // Update map colors after initial load
        updateMapColors(
          mapRef.current,
          selectedProperty,
          config[selectedDashboard],
          legendMax,
          legendMin,
          trigger
        );
      } else {
        // Show snackbar for user interaction on subsequent loads
        setShowSnackbar(true);
      }
    };

    if (mapLoaded) {
      updateMap(); // Update map immediately if already loaded
    } else {
      mapRef.current.on("load", updateMap); // Otherwise, update when map loads
    }

    // Cleanup to remove event listeners when component unmounts or dependencies change
    return () => {
      if (mapRef.current) {
        mapRef.current.off("load", updateMap);
      }
    };
  }, [
    geoJsonData,
    selectedFeatures,
    mapRef,
    mapLoaded,
    selectedProperty,
    config,
    selectedDashboard,
    legendMax,
    legendMin,
    firstLayerLoaded,
  ]);



  // useEffect for handling tooltip display on alt-click
  useEffect(() => {
    if (!mapRef.current) return;

    // Function to handle displaying tooltip on alt-click
    const handleAltClick = (e) => {
      if (!e.originalEvent.altKey) return; // Only proceed if alt key is pressed

      if (e.features.length) {
        const feature = e.features[0];
        const { x, y } = e.point;

        // Get tooltip property values based on selected dashboard configuration
        const propertyConfig = config[selectedDashboard].tooltipProperties;
        const propertyValues = propertyConfig.map((property) => ({
          property,
          value:
            feature.properties[property] !== undefined
              ? feature.properties[property]
              : "N/A",
        }));

        // Set tooltip position and content
        setTooltip({
          x: Math.min(x, window.innerWidth - 200),
          y: Math.min(y, window.innerHeight - 100),
          propertyValues,
        });
      }
    };

    // Function to hide tooltip on mouse leave
    const handleMouseLeave = () => {
      setTooltip({ x: 0, y: 0, propertyValues: [] });
    };

    // Add event listeners for click and mouseleave on fill and line layers
    mapRef.current.on("click", "geojson-fill-layer", handleAltClick);
    mapRef.current.on("mouseleave", "geojson-fill-layer", handleMouseLeave);
    mapRef.current.on("click", "geojson-line-layer", handleAltClick);
    mapRef.current.on("mouseleave", "geojson-line-layer", handleMouseLeave);

    // Cleanup event listeners on unmount or dependency change
    return () => {
      if (mapRef.current) {
        mapRef.current.off("click", "geojson-fill-layer", handleAltClick);
        mapRef.current.off("mouseleave", "geojson-fill-layer", handleMouseLeave);
        mapRef.current.off("click", "geojson-line-layer", handleAltClick);
        mapRef.current.off("mouseleave", "geojson-line-layer", handleMouseLeave);
      }
    };
  }, [selectedProperty, mapRef, selectedDashboard, config]);

  // Function to handle zooming map to the bounds of the current GeoJSON data
  const handleZoomToData = () => {
    const bounds = new mapboxgl.LngLatBounds();
    geoJsonData.features.forEach((feature) => {
      if (feature.geometry.type === "Point") {
        bounds.extend(feature.geometry.coordinates); // Extend bounds with point coordinates
      } else if (["LineString", "MultiPoint"].includes(feature.geometry.type)) {
        feature.geometry.coordinates.forEach((coord) => bounds.extend(coord)); // Extend bounds with line or multi-point coordinates
      } else if (["Polygon", "MultiLineString"].includes(feature.geometry.type)) {
        feature.geometry.coordinates.forEach((ring) =>
          ring.forEach((coord) => bounds.extend(coord)) // Extend bounds with polygon rings
        );
      } else if (feature.geometry.type === "MultiPolygon") {
        feature.geometry.coordinates.forEach((polygon) =>
          polygon.forEach((ring) =>
            ring.forEach((coord) => bounds.extend(coord)) // Extend bounds with multipolygon rings
          )
        );
      }
    });
    // Fit map to the calculated bounds with padding
    mapRef.current.fitBounds(bounds, { padding: 20 });
    setShowSnackbar(false); // Hide snackbar after zooming
  };


  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{ height: "100%", width: "100%", position: "relative" }}
    >

      {/* Render Tooltip using React Portal to avoid DOM nesting issues */}
      {mapContainer.current &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            propertyValues={tooltip.propertyValues}
          />,
          mapContainer.current
        )}

      {/* Render MapSelectionLogic for selection features on the map */}
      {mapContainer.current && (
        <MapSelectionLogic
          mapRef={mapRef}
          geoJsonData={geoJsonData}
          setSelectedFeatures={setSelectedFeatures}
          selectedFeatures={selectedFeatures}
          selectionBoxParent={mapContainer.current}
          selectedDashboard={selectedDashboard}
          currentScenario={currentScenario}
        />
      )}

      {/* Render ColorCodingLogic to apply color coding to the map based on selected property */}
      {mapLoaded && (
        <ColorCodingLogic
          map={mapRef.current}
          selectedProperty={selectedProperty}
          propertyConfig={config[selectedDashboard]}
          legendMax={legendMax}
          legendMin={legendMin}
          selectedIndex={selectedIndex}
          colorScale={colorScale}
          belowMinColor={belowMinColor}
          aboveMaxColor={aboveMaxColor}
        />
      )}

      {/* Snackbar to prompt user to zoom to data, with a button to trigger zoom
      <Snackbar
        open={showSnackbar}
        message="Zoom to data?"
        action={
          <Button color="primary" size="small" onClick={handleZoomToData}>
            Zoom to Data
          </Button>
        }
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        ContentProps={{
          style: {
            left: "50%",
          },
        }}
      /> */}
    </div>
  );
}

export default React.memo(Map);
