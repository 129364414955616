import * as d3 from 'd3';

export const renderEnergyAnnualEndUseGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!Array.isArray(selectedFeatures)) {
    console.error("selectedFeatures is not an array:", selectedFeatures);
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature.geometry?.type !== 'Point'
  );

  const annualData = {
    heating: 0,
    cooling: 0,
    equipment: 0,
    lighting: 0,
    hotWater: 0,
    PV: 0,
  };

  filteredFeatures.forEach((feature) => {
    annualData.equipment += feature.properties?.["Equipment[KWh][Yr]"] || 0;
    annualData.lighting += feature.properties?.["Lighting[KWh][Yr]"] || 0;
    annualData.hotWater += feature.properties?.["HotWater[KWh][Yr]"] || 0;
    annualData.PV += feature.properties?.["PV[KWh][Yr]"] || 0;
    annualData.cooling += feature.properties?.["Cooling[KWh][Yr]"] || 0;
    annualData.heating += feature.properties?.["Heating[KWh][Yr]"] || 0;
  });

  // Separate the PV data
  const pvData = annualData.PV;
  delete annualData.PV;  // Remove PV from the annualData for stacking

  // Calculate total energy use for stacked bar (excluding PV)
  const nonPvTotalEnergyUse = d3.sum(Object.values(annualData));

  // Calculate total energy use including PV
  const totalEnergyUse = pvData + nonPvTotalEnergyUse;

  const keys = Object.keys(annualData).filter(key => annualData[key] > 0);

  if (!keys.length) {
    console.error("No valid keys found for annualData:", annualData);
    return;
  }

  const margin = { top: 20, right: 40, bottom: 90, left: 20 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = dashboardConfig?.chartSettings?.height || 70; // Adjusted height for a single bar

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height * 2 + margin.top + margin.bottom)  // Adjust height to fit both bars
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // PV bar (placed above the stacked bar)
  const pvBarHeight = height / 2;  // Height of the PV bar
  const pvScale = d3.scaleLinear()
    .domain([0, totalEnergyUse])
    .range([0, width]);

  // Position both bars at the same x-axis starting point (x = 0)
  svg.append('rect')
    .attr('x', 0)  // Start at the same x position as the stacked bar
    .attr('y', 0)
    .attr('height', pvBarHeight)
    .attr('width', pvScale(pvData))
    .attr('fill', '#9C27B0');  // PV color

  // Add text label for PV bar
  svg.append('text')
    .attr('x', pvScale(pvData) + 5)
    .attr('y', pvBarHeight / 2)
    .attr('dy', '.35em')
    .style('font-size', '12px')
    .style('fill', '#000')
    .text(`${d3.format('.2s')(pvData)} `);

  // Stacked bar for other categories (placed below the PV bar)
  const x = d3.scaleLinear()
    .domain([0, totalEnergyUse])
    .nice()
    .range([0, width]);

  const y = d3.scaleBand()
    .range([pvBarHeight + 10, pvBarHeight + height])  // Adjusted y position for stacked bar
    .padding(0.1);

  const colorScale = d3.scaleOrdinal()
    .domain(['heating', 'cooling', 'equipment', 'lighting', 'hotWater', 'PV'])
    .range(['#cf5867', '#6faadd', '#959595', '#f2e75c', '#eec176', '#9C27B0']);

  let accumulatedValue = 0;  // Start accumulation from 0 for the stacked bar

  svg.selectAll('rect.stack')
    .data(keys)
    .enter().append('rect')
    .attr('class', 'stack')
    .attr('x', (d) => {
      const xValue = accumulatedValue;
      accumulatedValue += annualData[d];
      return x(xValue);  // Start at the same x position as the PV bar
    })
    .attr('y', pvBarHeight + 10)  // Adjusted y position for stacked bar
    .attr('height', y.bandwidth())
    .attr('width', d => x(annualData[d]) - x(0))
    .attr('fill', d => colorScale(d));

  // Add text label for stacked bar
  svg.append('text')
    .attr('x', x(nonPvTotalEnergyUse) + 5)
    .attr('y', pvBarHeight + 10 + y.bandwidth() / 2)
    .attr('dy', '.35em')
    .style('font-size', '12px')
    .style('fill', '#000')
    .text(`${d3.format('.2s')(nonPvTotalEnergyUse)} `);

  // X-axis
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${pvBarHeight + height})`)  // Adjusted y position for x-axis
    .call(d3.axisBottom(x).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.x-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // X-axis label
  svg.append("text")
    .attr("class", "x-axis-label")
    .attr("x", width)
    .attr("y", pvBarHeight + height + 30)  // Adjusted y position for x-axis label
    .attr("text-anchor", "middle")
    .style("font-size", "10px")
    .text("kWh");

  // Legend
  const legend = svg.append('g')
    .attr('transform', `translate(0, ${pvBarHeight + height + 30})`);  // Adjusted y position for legend

  const categories = ['equipment', 'lighting', 'hotWater', 'cooling', 'heating', 'PV'];
  const legendXOffset = 20;
  const legendYOffset = 10;

  categories.forEach((category, i) => {
    const row = Math.floor(i / 3);
    const col = i % 3;

    legend.append('rect')
      .attr('x', col * 100)
      .attr('y', row * 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(category));

    legend.append('text')
      .attr('x', col * 100 + legendXOffset)
      .attr('y', row * 20 + legendYOffset)
      .text(category)
      .style('font-size', '12px')
      .style('fill', '#000');
  });
};
