import * as d3 from 'd3';

export const renderLoadDurationCurveGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  // Ensure selectedFeatures is an array and contains valid elements
  if (!Array.isArray(selectedFeatures) || selectedFeatures.length === 0) {
    console.warn("Invalid or empty selectedFeatures:", selectedFeatures);
    return;
  }

  // Initialize arrays to store the summed hourly data (8760 hours for a year)
  let summedGasData = Array(8760).fill(0);
  let summedElectricityData = Array(8760).fill(0);

  // Sum the data for each hour across all selected features
  selectedFeatures.forEach((feature) => {
    const gas = Array.isArray(feature.properties?.["GasDemand_KWh"]) ? feature.properties["GasDemand_KWh"] : [];
    const electricity = Array.isArray(feature.properties?.["ElectricityDemand_KWh"]) ? feature.properties["ElectricityDemand_KWh"] : [];

    for (let hour = 0; hour < 8760; hour++) {
      if (gas[hour] !== undefined) summedGasData[hour] += gas[hour] || 0;
      if (electricity[hour] !== undefined) summedElectricityData[hour] += electricity[hour] || 0;
    }
  });

  // Ensure we have valid data before proceeding
  if (summedGasData.every(d => d === 0) && summedElectricityData.every(d => d === 0)) {
    console.warn("No valid data to display in the load duration curve.");
    return;
  }

  // Sort the summed data from highest to lowest
  summedGasData.sort((a, b) => b - a);
  summedElectricityData.sort((a, b) => b - a);

  const margin = { top: 20, right: 30, bottom: 60, left: 50 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = dashboardConfig?.chartSettings?.height || 250 - margin.top - margin.bottom;

  // Clear any existing content before appending new SVG
  d3.select(chartRef.current).select("svg").remove();

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleLinear()
    .domain([0, summedGasData.length])
    .range([0, width]);

  const y = d3.scaleLinear()
    .domain([0, d3.max([...summedGasData, ...summedElectricityData]) || 0])
    .nice()
    .range([height, 0]);

  // Create the line generator function
  const lineGenerator = d3.line()
    .x((d, i) => x(i))
    .y(d => y(d));

  // Render the electricity curve (blue)
  svg.append('path')
    .datum(summedElectricityData)
    .attr('fill', 'none')
    .attr('stroke', '#1569C7')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  // Render the gas curve (yellow)
  svg.append('path')
    .datum(summedGasData)
    .attr('fill', 'none')
    .attr('stroke', '#FFB100')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  // Render the axes
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).ticks(8).tickFormat(d3.format(".0s")));

  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

      // Y-axis label
  svg.append("text")
  .attr("class", "y-axis-label")
  .attr("x", -(height / 2))
  .attr("y", -margin.left + 15)
  .attr("text-anchor", "middle")
  .attr("transform", "rotate(-90)")
  .style("font-size", "10px")
  .text("kW");

// X-axis label
svg.append("text")
  .attr("class", "x-axis-label")
  .attr("x", width)
  .attr("y", height + 20)
  .attr("text-anchor", "middle")
  .style("font-size", "10px")
  .text("Hour");

  // Add legend
  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 30})`);

  legend.append('rect')
    .attr('x', 0)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#1569C7');

  legend.append('text')
    .attr('x', 20)
    .attr('y', 5)
    .text('Electricity')
    .style('font-size', '10px')
    .style('fill', '#000');

  legend.append('rect')
    .attr('x', 100)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#FFB100');

  legend.append('text')
    .attr('x', 120)
    .attr('y', 5)
    .text('Gas')
    .style('font-size', '10px')
    .style('fill', '#000');
};
