import * as d3 from 'd3';

export const renderMobilityHourlyTripGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!Array.isArray(selectedFeatures)) {
    console.error("selectedFeatures is not an array:", selectedFeatures);
    return;
  }

  // Initialize hourly data arrays
  const hourlyData = {
    pedestrian_count: Array(24).fill(0),
    biker_count: Array(24).fill(0),
    vehicle_count: Array(24).fill(0),
  };

  // Aggregate hourly data
  selectedFeatures.forEach((feature) => {
    for (let hour = 0; hour < 24; hour++) {
      hourlyData.pedestrian_count[hour] +=
        feature.properties?.["pedestrian_count[HOUR]"]?.[hour] || 0;
      hourlyData.biker_count[hour] +=
        feature.properties?.["biker_count[HOUR]"]?.[hour] || 0;
      hourlyData.vehicle_count[hour] +=
        feature.properties?.["vehicle_count[HOUR]"]?.[hour] || 0;
    }
  });

  const margin = { top: 20, right: 10, bottom: 100, left: 40 };
  const width = (dashboardConfig?.chartSettings?.width || 360) - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 300) - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Create x and y scales
  const x = d3.scaleLinear()
    .domain([0, 23]) // 24 hours
    .range([0, width]);

  const y = d3.scaleLinear()
    .domain([
      0,
      Math.max(
        d3.max(hourlyData.pedestrian_count),
        d3.max(hourlyData.biker_count),
        d3.max(hourlyData.vehicle_count)
      ),
    ])
    .nice()
    .range([height, 0]);

  // Define line generator
  const lineGenerator = d3.line()
    .x((d, i) => x(i)) // Use hour index for x-axis
    .y((d) => y(d)) // Use value for y-axis
    .curve(d3.curveMonotoneX); // Smooth lines

  // Color scale for the lines
  const colorScale = d3.scaleOrdinal()
    .domain(['pedestrian_count', 'biker_count', 'vehicle_count'])
    .range(['#f24726', '#fac710', '#2d9bf0']); // Red, Yellow, Blue

  // Add vertical gridlines
  svg.append('g')
    .selectAll('line')
    .data(d3.range(0, 24))
    .enter()
    .append('line')
    .attr('x1', d => x(d))
    .attr('x2', d => x(d))
    .attr('y1', 0)
    .attr('y2', height)
    .attr('stroke', '#d3d3d3') // Light grey color
    .attr('stroke-width', 0.5)
    .attr('stroke-dasharray', '2,2'); // Dashed lines

  // Plot each line
  Object.keys(hourlyData).forEach((key) => {
    svg.append('path')
      .datum(hourlyData[key])
      .attr('fill', 'none')
      .attr('stroke', colorScale(key))
      .attr('stroke-width', 2)
      .attr('d', lineGenerator);
  });

  // Add x-axis
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).ticks(24).tickFormat((d) => `${d}`));

  svg.selectAll('.x-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // Add y-axis
  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // X-axis label
  svg.append('text')
    .attr('class', 'x-axis-label')
    .attr('x', width / 2)
    .attr('y', height + margin.bottom - 20)
    .attr('text-anchor', 'middle')
    .style('font-size', '12px')
    .text('Hour of Day');

  // Y-axis label
  svg.append('text')
    .attr('class', 'y-axis-label')
    .attr('x', -height / 2)
    .attr('y', -margin.left + 10)
    .attr('text-anchor', 'middle')
    .attr('transform', 'rotate(-90)')
    .style('font-size', '12px')
    .text('Count');

  // Legend at the bottom in a single row
  const legend = svg.append('g')
    .attr('transform', `translate(${width / 2 - 120}, ${height + 40})`);

  const categories = ['pedestrian_count', 'biker_count', 'vehicle_count'];

  categories.forEach((category, i) => {
    legend.append('rect')
      .attr('x', i * 120-30)
      .attr('y', 0)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(category));

    legend.append('text')
      .attr('x', i * 120 -15)
      .attr('y', 10)
      .text(category.replace('_count', '').toUpperCase())
      .style('font-size', '10px')
      .style('fill', '#000');
  });
};
