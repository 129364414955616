import Papa from 'papaparse';
// Import proj4 library
import proj4 from "proj4";
// Import the open-location-code library for generating OLC codes
import OpenLocationCode from "open-location-code";

// Define custom projection strings
const EPSG32118 =
  "+proj=lcc +lat_1=40.66666666666666 +lat_2=41.03333333333333 " +
  "+lat_0=40.16666666666666 +lon_0=-74 +x_0=300000 +y_0=0 " +
  "+datum=NAD83 +units=m +no_defs";

const EPSG2263 =
  "+proj=lcc +lat_1=40.66666666666666 +lat_2=41.03333333333333 " +
  "+lat_0=40.16666666666666 +lon_0=-74 +x_0=300000 +y_0=0 " +
  "+datum=NAD83 +units=m +no_defs";

const WGS84 = "+proj=longlat +datum=WGS84 +no_defs";


// Register the projections with proj4
proj4.defs("EPSG:2263", EPSG2263);
proj4.defs("EPSG:32118", EPSG32118);
proj4.defs("EPSG:4326", WGS84);

/**
 * Truncates a file name by removing its extension, replacing underscores with spaces,
 * and shortening it if it exceeds a specified maximum length.
 * @param {string} fileName - The file name to truncate.
 * @param {number} maxLength - The maximum allowed length for the truncated file name.
 * @returns {string} - The truncated file name.
 */
export const truncateFileName = (fileName, maxLength = 30) => {
  if (!fileName || typeof fileName !== "string") {
    console.error("Invalid fileName provided to truncateFileName:", fileName);
    return "Unknown File"; // Fallback value for invalid or undefined file names
  }

  const nameWithoutExtension = fileName.replace(/\.geojson$/i, "");
  const nameWithSpaces = nameWithoutExtension.replace(/_/g, " ");
  if (nameWithSpaces.length <= maxLength) return nameWithSpaces;

  const start = nameWithSpaces.slice(0, Math.floor(maxLength / 2));
  const end = nameWithSpaces.slice(-Math.floor(maxLength / 2));
  return `${start}...${end}`;
};


export const convertCsvToGeoJson = async (csvFile) => {
  return new Promise((resolve, reject) => {
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        try {
          const data = results.data;
          const headers = results.meta.fields.map((header) => header.trim());

          const { latitudeIndex, longitudeIndex } = findLatLongColumns(headers);

          const rectangleSize = 0.001; // Approximately 100 meters at the equator

          const features = data.map((row) => {
            const properties = {};

            headers.forEach((header) => {
              const value = row[header]?.trim();
              properties[header] = parseIntelligently(header, value);
            });

            // Extract and validate Latitude and Longitude
            const latitude = parseFloat(row[headers[latitudeIndex]]?.trim());
            const longitude = parseFloat(row[headers[longitudeIndex]]?.trim());

            if (!isNaN(latitude) && !isNaN(longitude)) {
              const polygonCoordinates = [
                [
                  [longitude - rectangleSize, latitude - rectangleSize],
                  [longitude + rectangleSize, latitude - rectangleSize],
                  [longitude + rectangleSize, latitude + rectangleSize],
                  [longitude - rectangleSize, latitude + rectangleSize],
                  [longitude - rectangleSize, latitude - rectangleSize],
                ],
              ];

              return {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: polygonCoordinates,
                },
                properties,
              };
            }
            return null;
          }).filter((feature) => feature !== null);

          const geoJson = {
            type: 'FeatureCollection',
            features,
          };

          //console.log('Generated GeoJSON:', JSON.stringify(geoJson, null, 2));
          resolve(geoJson);
        } catch (error) {
          reject(`Error converting CSV: ${error.message}`);
        }
      },
      error: function (error) {
        reject(`Failed to parse CSV file: ${error.message}`);
      },
    });
  });
};


/**
 * Intelligently parses a value from the CSV using the property name and value.
 * @param {string} propertyName - The name of the property.
 * @param {string} value - The value to parse.
 * @returns {string|number|Date|null} - The parsed value.
 */
export function parseIntelligently(propertyName, value) {
  if (value === undefined || value === null || value.trim() === '') {
    return null; // Treat empty values as null
  }

  const trimmedValue = value.trim();
  const nameLower = propertyName.toLowerCase();

  // Check for date-like property names
  const dateKeywords = ['date', 'time', 'timestamp'];
  if (dateKeywords.some((keyword) => nameLower.includes(keyword))) {
    // Try to parse the value as a date
    const dateValue = new Date(trimmedValue);
    if (!isNaN(dateValue.getTime())) {
      return dateValue.toISOString(); // Convert to ISO string format
    } else {
      return trimmedValue; // If not a valid date, keep as-is
    }
  }

  // Check for boolean values
  const booleanValues = ['true', 'false'];
  if (booleanValues.includes(trimmedValue.toLowerCase())) {
    return trimmedValue.toLowerCase() === 'true';
  }

  // Check for ID or code-like property names
  const idKeywords = ['id', 'code', 'number', 'num', 'permit', 'bin'];
  if (idKeywords.some((keyword) => nameLower.includes(keyword))) {
    // Keep as string, even if it looks like a number
    return trimmedValue;
  }

  // **Additional string keywords to avoid number parsing for certain fields**
  const stringKeywords = ['name', 'street', 'address', 'location', 'building type', 'description'];
  if (stringKeywords.some((keyword) => nameLower.includes(keyword))) {
    // Keep as string
    return trimmedValue;
  }

  // Only parse as number if the entire value is numeric
  const numericValue = trimmedValue.replace(/,/g, ''); // Remove commas if necessary
  if (/^-?\d+(\.\d+)?$/.test(numericValue)) {
    return parseFloat(numericValue);
  }

  // Return as string
  return trimmedValue;
}



export function findLatLongColumns(headers){
  // Possible variations of column names for latitude and longitude
  const latitudeAliases = ["latitude", "lat", "y", "y point", "latitude point"];
  const longitudeAliases = ["longitude", "lon", "lng", "x", "x point", "longitude point"];

  // Find the index of the latitude column
  const latitudeIndex = headers.findIndex((header) =>
    latitudeAliases.includes(header.toLowerCase())
  );

  // Find the index of the longitude column
  const longitudeIndex = headers.findIndex((header) =>
    longitudeAliases.includes(header.toLowerCase())
  );

  if (latitudeIndex === -1 || longitudeIndex === -1) {
    throw new Error("Could not find latitude or longitude columns in the CSV headers.");
  }

  return { latitudeIndex, longitudeIndex };
};


/**
 * Parses a value from the CSV. Converts numeric strings to floats and keeps other values as-is.
 * @param {string} value - The value to parse.
 * @returns {string|number|null} - The parsed value (float, string, or null if empty).
 */
const parseValue = (value) => {
  if (value === undefined || value === null || value.trim() === "") {
    return null; // Treat empty values as null
  }
  const parsedNumber = parseFloat(value);
  return !isNaN(parsedNumber) ? parsedNumber : value; // Convert to float if it's a valid number
};

/**
 * Determines the types of property values in a GeoJSON property.
 * @param {Object} geojson - GeoJSON object to analyze.
 * @returns {Object} - An object with the property names as keys and their types as values.
 */
export function determinePropertyTypes(geojson) {
  const propertyTypes = {};
  geojson.features.forEach((feature) => {
    Object.entries(feature.properties).forEach(([key, value]) => {
      const valueType = Array.isArray(value) ? "array" : typeof value;
      if (!propertyTypes[key]) {
        propertyTypes[key] = valueType;
      }
    });
  });
  return propertyTypes;
}

// Function to determine the type of a property value
export function determineType(value) {
  if (typeof value === "number") {
    return "number";
  } else if (typeof value === "string") {
    return "category";
  } else if (
    Array.isArray(value) &&
    value.every((item) => typeof item === "number")
  ) {
    return "array";
  } else if (
    Array.isArray(value) &&
    value.every((item) => typeof item === "string")
  ) {
    return "array";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (value === null) {
    return "null";
  } else if (typeof value === "object") {
    return "object";
  } else {
    return "unknown";
  }
}



// round a number to the nearest reasonable number
export function reasonableRoundUp(number) {
    if (number < 10) return Math.ceil(number); // For small numbers, round to the nearest integer

    const magnitude = Math.pow(10, Math.floor(Math.log10(number))); // Find the scale of the number
    const firstDigit = Math.floor(number / magnitude); // Get the first significant digit

    // Determine the next "reasonable" rounding based on the first digit
    const reasonableValues = [1, 2, 5, 10]; // Reasonable leading digits for most cases

    // Find the next reasonable value to round up to
    let roundedValue;
    for (let i = 0; i < reasonableValues.length; i++) {
        const candidate = reasonableValues[i] * magnitude;
        if (candidate >= number) {
            roundedValue = candidate;
            break;
        }
    }

    // If no candidate was large enough, round to the next order of magnitude
    if (!roundedValue) {
        roundedValue = 10 * magnitude;
    }

    return roundedValue;
}

export function reasonableRoundDown(number) {
    if (number < 10) return Math.floor(number); // For small numbers, round down to the nearest integer

    const magnitude = Math.pow(10, Math.floor(Math.log10(number))); // Find the scale of the number
    const firstDigit = Math.floor(number / magnitude); // Get the first significant digit

    // Reasonable values for leading digits, going down
    const reasonableValues = [10, 5, 2, 1];

    // Find the closest reasonable value to round down to
    let roundedValue;
    for (let i = 0; i < reasonableValues.length; i++) {
        const candidate = reasonableValues[i] * magnitude;
        if (candidate <= number) {
            roundedValue = candidate;
            break;
        }
    }

    // If no candidate was small enough, round down to the previous order of magnitude
    if (!roundedValue) {
        roundedValue = 0.1 * magnitude;
    }

    return roundedValue;
}


// Parsing string arrays that look like numeric arrays
export function parseStringArrays(properties) {
  const arrayPattern = /^\d+(\.\d+)?(,\s*\d+(\.\d+)?)*$/;
  Object.keys(properties).forEach((key) => {
    if (key === "ID_ESL") return;
    const value = properties[key];
    if (typeof value === "string" && arrayPattern.test(value)) {
      try {
        const parsedArray = value.split(",").map((item) => parseFloat(item.trim()));
        if (parsedArray.every((item) => !isNaN(item))) {
          properties[key] = parsedArray;
        }
      } catch (error) {
        console.error(`Error parsing property "${key}":`, error);
      }
    }
  });
  return properties;
}

// Check if coordinates are within typical lat-lon ranges
export function coordinatesOutOfRange(coordinates) {
  try {
    const flatCoords = coordinates.flat(Infinity);
    const [x, y] = flatCoords.slice(0, 2);
    return x < -180 || x > 180 || y < -90 || y > 90;
  } catch (error) {
    return true;
  }
}

// Recursive function to reproject coordinates
export function reprojectCoordinates(coords, sourceProj, destProj) {
  if (typeof coords[0] === "number" && typeof coords[1] === "number") {
    return proj4(proj4.defs(sourceProj), proj4.defs(destProj), coords);
  } else if (Array.isArray(coords)) {
    return coords.map((coord) => reprojectCoordinates(coord, sourceProj, destProj));
  } else {
    throw new Error("Invalid coordinate format");
  }
}

// Function to add or use ID_ESL as unique IDs for GeoJSON features with prefixes
export function addIdToGeoJson(data, prefix) {
  data.features = data.features.map((feature, index) => {
    if (!feature.properties.ID_ESL) {
      feature.properties.ID_ESL = `${prefix}-${index.toString()}`;
    } else if (!feature.properties.ID_ESL.startsWith(prefix)) {
      feature.properties.ID_ESL = `${prefix}-${feature.properties.ID_ESL}`;
    }
    return { ...feature, id: feature.properties.ID_ESL };
  });
  return data;
}

// Function to collect all properties associated with DataDashboard
export function collectDataDashboardProperties(geoJsonFiles) {
  const dataDashboardProperties = new Set();
  geoJsonFiles.forEach((file) => {
    file.data.features.forEach((feature) => {
      Object.keys(feature.properties || {}).forEach((prop) => {
        dataDashboardProperties.add(prop);
      });
    });
  });
  return Array.from(dataDashboardProperties);
}



// Function to generate default colors; can be customized as needed
export function generateDefaultColor(index) {
  const colors = ["#72F2EB", "#FF4858", "#FFB30D", "#88C7F0", "#F07A78"];
  return colors[index % colors.length];
}

// Function to read file as text
export function readFileAsText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        resolve(data);
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        reject(error);
      }
    };
    reader.onerror = (e) => {
      console.error(`Error reading file ${file.name}:`, e);
      reject(e);
    };
    reader.readAsText(file);
  });
}


/**
 * Adds a UBID to each feature in a GeoJSON object.
 * @param {Object} geojson - The GeoJSON object to process.
 * @param {number} [codeLength=10] - Desired length of the UBID code.
 * @returns {Object} - The modified GeoJSON object with UBID added to each feature.
 */
export function addUBIDtoGeoJSON(geojson, codeLength = 10) {
  //console.log("Starting to add UBIDs to GeoJSON features.");
  geojson.features.forEach((feature, index) => {
    //console.log(`Processing feature at index ${index} with geometry type: ${feature.geometry.type}`);
    if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
      try {
        // Generate UBID for the feature
        const ubid = generateUBIDForFeature(feature, codeLength);
        //console.log(`Generated UBID for feature at index ${index}: ${ubid}`);

        // Ensure properties exist and assign UBID
        feature.properties = feature.properties || {};
        feature.properties.UBID = ubid;
      } catch (error) {
        console.error(`Failed to generate UBID for feature at index ${index}: ${error}`);
      }
    } else {
      //console.log(`Skipping feature at index ${index} as it is not a Polygon or MultiPolygon.`);
    }
  });
  //console.log("Finished adding UBIDs to GeoJSON features.");
  return geojson;
}

/**
 * Generates a UBID for a single GeoJSON feature.
 * @param {Object} feature - The GeoJSON feature to process.
 * @param {number} [codeLength=10] - Desired length of the UBID code.
 * @returns {string} - The generated UBID.
 */
export function generateUBIDForFeature(feature, codeLength = 10) {
  //console.log("Generating UBID for feature.");
  if (!feature.geometry || !(feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon')) {
    console.error("Feature geometry must be a Polygon or MultiPolygon.");
    throw new Error('Feature geometry must be a Polygon or MultiPolygon');
  }

  // Calculate bounding box and centroid
  //console.log("Calculating bounding box and centroid.");
  const bbox = calculateBoundingBox(feature.geometry.coordinates);
  //console.log(`Bounding box calculated: ${JSON.stringify(bbox)}`);
  const centroid = calculateCentroid(bbox);
  //console.log(`Centroid calculated: ${JSON.stringify(centroid)}`);

  // Generate Open Location Codes for minima, maxima, and centroid
  const minCode = OpenLocationCode.encode(bbox.minY, bbox.minX, codeLength);
  const maxCode = OpenLocationCode.encode(bbox.maxY, bbox.maxX, codeLength);
  const centerCode = OpenLocationCode.encode(centroid.lat, centroid.lon, codeLength);
  //console.log(`Generated OLC codes - Min: ${minCode}, Max: ${maxCode}, Center: ${centerCode}`);

  // Construct UBID by combining OLC codes and bounding box info
  const ubid = `${centerCode}-${Math.round(bbox.minY)}-${Math.round(bbox.minX)}-${Math.round(bbox.maxY)}-${Math.round(bbox.maxX)}`;
  //console.log(`Constructed UBID: ${ubid}`);

  return ubid;
}

/**
 * Calculates the bounding box for a Polygon or MultiPolygon's coordinates.
 * @param {Array} coordinates - The coordinates of the feature's geometry.
 * @returns {Object} - The bounding box with minX, minY, maxX, and maxY.
 */
export function calculateBoundingBox(coordinates) {
  //console.log("Calculating bounding box.");
  let minX, minY, maxX, maxY;
  coordinates[0][0].forEach(([longitude, latitude], index) => {
    //console.log(`Processing coordinate at index ${index}: [${longitude}, ${latitude}]`);
    minX = minX === undefined ? longitude : Math.min(minX, longitude);
    maxX = maxX === undefined ? longitude : Math.max(maxX, longitude);
    minY = minY === undefined ? latitude : Math.min(minY, latitude);
    maxY = maxY === undefined ? latitude : Math.max(maxY, latitude);
  });
  //console.log(`Calculated bounding box: minX=${minX}, minY=${minY}, maxX=${maxX}, maxY=${maxY}`);
  return { minX, minY, maxX, maxY };
}

/**
 * Calculates the centroid for a bounding box.
 * @param {Object} bbox - The bounding box with minX, minY, maxX, and maxY.
 * @returns {Object} - The centroid coordinates with `lat` and `lon`.
 */
export function calculateCentroid(bbox) {
  //console.log("Calculating centroid.");
  const centroid = {
    lat: (bbox.minY + bbox.maxY) / 2,
    lon: (bbox.minX + bbox.maxX) / 2
  };
  //console.log(`Calculated centroid: lat=${centroid.lat}, lon=${centroid.lon}`);
  return centroid;
}

/**
 * Reprojects a single coordinate pair based on source and target projections.
 * @param {Array} coord - Array [lon, lat] representing a coordinate.
 * @param {string} fromProj - EPSG code or projection string of the source projection.
 * @param {string} toProj - EPSG code or projection string of the target projection.
 * @returns {Array} - Reprojected coordinate.
 */
export function reprojectCoordinate(coord, fromProj, toProj) {
  //console.log(`Reprojecting coordinate ${coord} from ${fromProj} to ${toProj}`);
  const reprojected = proj4(fromProj, toProj, coord);
  //console.log(`Reprojected coordinate: ${reprojected}`);
  return reprojected;
}

/**
 * Reprojects coordinates based on GeoJSON geometry type.
 * @param {Array} coordinates - Array of coordinates to reproject.
 * @param {string} type - Geometry type (e.g., 'Point', 'LineString').
 * @param {string} fromProj - EPSG code or projection string of the source projection.
 * @param {string} toProj - EPSG code or projection string of the target projection.
 * @returns {Array} - Reprojected coordinates.
 */
export function reprojectCoordinatesByType(
  coordinates,
  type,
  fromProj,
  toProj
) {
  //console.log(`Reprojecting coordinates of type ${type} from ${fromProj} to ${toProj}`);
  if (type === "Point") {
    return reprojectCoordinate(coordinates, fromProj, toProj);
  } else if (type === "LineString" || type === "MultiPoint") {
    return coordinates.map((coord) =>
      reprojectCoordinate(coord, fromProj, toProj)
    );
  } else if (type === "Polygon" || type === "MultiLineString") {
    return coordinates.map((ring) =>
      ring.map((coord) => reprojectCoordinate(coord, fromProj, toProj))
    );
  } else if (type === "MultiPolygon") {
    return coordinates.map((polygon) =>
      polygon.map((ring) =>
        ring.map((coord) => reprojectCoordinate(coord, fromProj, toProj))
      )
    );
  }
  return coordinates
}

/**
 * Reprojects all coordinates in a GeoJSON object from one projection to another.
 * @param {object} geojson - The GeoJSON object to reproject.
 * @param {string} fromProj - EPSG code or projection string of the source projection.
 * @param {string} toProj - EPSG code or projection string of the target projection.
 * @returns {object} - A new GeoJSON object with reprojected coordinates.
 */
export function reprojectGeoJSON(geojson, fromProj, toProj) {
  // Clone the GeoJSON object to avoid mutating the original
  const reprojectedGeoJSON = JSON.parse(JSON.stringify(geojson));

  // Iterate through each feature to reproject coordinates
  reprojectedGeoJSON.features = reprojectedGeoJSON.features.map((feature) => {
    feature.geometry.coordinates = reprojectCoordinatesByType(
      feature.geometry.coordinates,
      feature.geometry.type,
      fromProj,
      toProj
    );
    return feature;
  });

  return reprojectedGeoJSON;
}

// Function to convert strings that look like arrays in to actual arrays
export function convertStringToArray(str) {
  // Step 1: Trim whitespace and ensure it looks like a valid array
  const trimmedStr = str.trim();

  // Step 2: Check if the string starts with '[' and ends with ']'
  if (trimmedStr.startsWith("[") && trimmedStr.endsWith("]")) {
    try {
      // Step 3: Parse the string into an array
      const array = JSON.parse(trimmedStr);
      // Step 4: Ensure the result is actually an array
      if (Array.isArray(array)) {
        return array;
      } else {
        throw new Error("The parsed result is not an array");
      }
    } catch (error) {
      console.error("Error parsing string:", error.message);
    }
  } else {
    console.error("Invalid array format");
  }
  return null;
}

// Function to convert strings that look like numbers in to actual numbers
export function convertStringToNumber(str) {
  // Step 1: Trim whitespace
  const trimmedStr = str.trim();

  // Step 2: Attempt to convert the string to a number
  const number = Number(trimmedStr);

  // Step 3: Check if the result is a valid number
  if (!isNaN(number)) {
    return number;
  } else {
    console.error("Invalid number format");
  }
  return null;
}

/**
 * Calculates the minimum values for numeric properties in a GeoJSON object.
 * @param {Object} geojson - GeoJSON object to process.
 * @returns {Object} - An object with the minimum values for each numeric property.
 */
export function calculateMinValues(geojson) {
  const minValues = {};
  geojson.features.forEach((feature) => {
    Object.entries(feature.properties).forEach(([key, value]) => {
      if (typeof value === "number") {
        if (!(key in minValues) || value < minValues[key]) {
          minValues[key] = value;
        }
      }
    });
  });
  return minValues;
}

/**
 * Calculates the maximum values for numeric properties in a GeoJSON object.
 * @param {Object} geojson - GeoJSON object to process.
 * @returns {Object} - An object with the maximum values for each numeric property.
 */
export function calculateMaxValues(geojson) {
  const maxValues = {};
  geojson.features.forEach((feature) => {
    Object.entries(feature.properties).forEach(([key, value]) => {
      if (typeof value === "number") {
        if (!(key in maxValues) || value > maxValues[key]) {
          maxValues[key] = value;
        }
      }
    });
  });
  return maxValues;
}

/**
 * Calculates the sum of values for numeric properties in a GeoJSON object.
 * @param {Object} geojson - GeoJSON object to process.
 * @returns {Object} - An object with the sum of values for each numeric property.
 */
export function calculateSumValues(geojson) {
  const sumValues = {};
  geojson.features.forEach((feature) => {
    Object.entries(feature.properties).forEach(([key, value]) => {
      if (typeof value === "number") {
        sumValues[key] = (sumValues[key] || 0) + value;
      }
    });
  });
  return sumValues;
}

// Helper function to generate sums for each property in each scenario
export function calculatePropertySums(geoJsonFilesByDashboard) {
    const scenarioSums = {};
  
    // Iterate through each dashboard and its associated GeoJSON files
    Object.keys(geoJsonFilesByDashboard).forEach((dashboardName) => {
      const dashboardFiles = geoJsonFilesByDashboard[dashboardName];
  
      // Iterate through each file in the dashboard
      dashboardFiles.forEach((file, fileIndex) => {
        // Create a unique scenario name based on dashboard and file index
        const scenarioName = `${dashboardName}_Scenario${fileIndex + 1}`;
        if (!scenarioSums[scenarioName]) {
          scenarioSums[scenarioName] = {};
        }
  
        // Iterate through each feature in the file
        file.data.features.forEach((feature) => {
          // Check if the feature geometry type is one of the valid types
          const validTypes = [
            "Point",
            "Polygon",
            "MultiPolygon",
            "LineString",
            "MultiLineString",
          ];
          if (validTypes.includes(feature.geometry.type)) {
            // Iterate through each property of the feature
            Object.keys(feature.properties || {}).forEach((prop) => {
              const value = feature.properties[prop];
              // Check if the value is numeric and valid
              if (value !== null && !isNaN(value)) {
                scenarioSums[scenarioName][prop] =
                  (scenarioSums[scenarioName][prop] || 0) + Number(value);
              }
            });
          }
        });
      });
    });
  
    return scenarioSums;
  }



/**
 * Provides a fallback value for undefined or null values.
 * @param {*} value - The value to test.
 * @param {*} fallback - The fallback value to use if value is undefined or null.
 * @returns {*} - The original value if valid, otherwise the fallback value.
 */
export function provideFallback(value, fallback) {
  return value !== undefined && value !== null ? value : fallback;
}

/**
 * Converts a string to a URL slug.
 * @param {string} str - The string to convert.
 * @returns {string} - The converted URL slug.
 */
export function convertStringToSlug(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}

/**
 * Generates colorstops given a min and max value and a color scale.
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @param {Array} colorScale - Array of colors to use for the scale.
 * @returns {Array} - An array of color stops for use in mapping tools.
 */
export function generateColorStops(min, max, colorScale) {
  const numStops = colorScale.length;
  const step = (max - min) / (numStops - 1);
  return colorScale.map((color, index) => [min + step * index, color]);
}

/**
 * Parses property names and replaces underscores and brackets with spaces.
 * @param {string} propertyName - The property name to parse.
 * @returns {string} - The cleaned-up property name.
 */
export function parsePropertyName(propertyName) {
  return propertyName.replace(/[_\[\]]/g, " ").trim();
}

/**
 * Converts a number to an appropriate display format (e.g., 1000 -> 1K).
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string.
 */
export function formatNumberDisplay(number) {
  if (number >= 1e9) {
    return `${(number / 1e9).toFixed(1)}B`;
  } else if (number >= 1e6) {
    return `${(number / 1e6).toFixed(1)}M`;
  } else if (number >= 1e3) {
    return `${(number / 1e3).toFixed(1)}K`;
  } else {
    return number.toString();
  }
}


