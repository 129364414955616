import * as d3 from 'd3';

export const renderMobilityDayTripGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!Array.isArray(selectedFeatures)) {
    console.error("selectedFeatures is not an array:", selectedFeatures);
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature.geometry?.type !== 'Point'
  );

  // Initialize mobility data
  const mobilityData = {
    pedestrian_count: 0.0,
    biker_count: 0.0,
    vehicle_count: 0.0,
  };

  // Accumulate mobility data
  filteredFeatures.forEach((feature) => {
    mobilityData.pedestrian_count += feature.properties?.["pedestrian_count[DAILY]"] || 0;
    mobilityData.biker_count += feature.properties?.["biker_count[DAILY]"] || 0;
    mobilityData.vehicle_count += feature.properties?.["vehicle_count[DAILY]"] || 0;
  });

  const keys = Object.keys(mobilityData).filter((key) => mobilityData[key] > 0);

  if (!keys.length) {
    console.error("No valid keys found for mobilityData:", mobilityData);
    return;
  }

  const margin = { top: 20, right: 80, bottom: 100, left: 20 };
  const width = (dashboardConfig?.chartSettings?.width || 350) - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 200) - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Color scale
  const colorScale = d3.scaleOrdinal()
    .domain([ 'pedestrian_count', 'biker_count', 'vehicle_count'])
    .range(['#f24726', '#fac710', '#2d9bf0']);

  // Total people count
  const totalPeopleCount = d3.sum(Object.values(mobilityData));

  const x = d3.scaleLinear()
    .domain([0, totalPeopleCount])
    .nice()
    .range([0, width]);

  const barHeight = height / 2;

  // Stacked bar for daily counts
  let accumulatedValue = 0;

  svg.selectAll('rect.daily')
    .data(keys)
    .enter()
    .append('rect')
    .attr('class', 'daily')
    .attr('x', (d) => {
      const xValue = accumulatedValue;
      accumulatedValue += mobilityData[d];
      return x(xValue);
    })
    .attr('y', 0)
    .attr('height', barHeight)
    .attr('width', (d) => x(mobilityData[d]) - x(0))
    .attr('fill', (d) => colorScale(d));

  // Add text label for total counts
  svg.append('text')
    .attr('x', x(totalPeopleCount) + 5)
    .attr('y', barHeight / 2)
    .attr('dy', '.35em')
    .style('font-size', '12px')
    .style('fill', '#000')
    .text(`Total: ${d3.format('.2s')(totalPeopleCount)}`);

  // X-axis
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${barHeight + 20})`)
    .call(d3.axisBottom(x).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.x-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // X-axis label
  svg.append('text')
    .attr('class', 'x-axis-label')
    .attr('x', width)
    .attr('y', barHeight + 50)
    .attr('text-anchor', 'middle')
    .style('font-size', '10px')
    .text('People');

  // Legend
  const legend = svg.append('g')
    .attr('transform', `translate(0, ${barHeight + 60})`);

  const categories = ['pedestrian_count', 'biker_count', 'vehicle_count'];
  const legendXOffset = 20;
  const legendYOffset = 10;

  categories.forEach((category, i) => {
    const row = Math.floor(i / 2);
    const col = i % 2;

    legend.append('rect')
      .attr('x', col * 140)
      .attr('y', row * 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(category));

    legend.append('text')
      .attr('x', col * 140 + legendXOffset)
      .attr('y', row * 20 + legendYOffset)
      .text(category.replace('_count', '').toUpperCase())
      .style('font-size', '10px')
      .style('fill', '#000');
  });
};
