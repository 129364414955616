import * as d3 from 'd3';

export const renderWinterEnergyLoadGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!chartRef || !chartRef.current) {
    console.error('Invalid chartRef provided.');
    return;
  }

  if (!Array.isArray(selectedFeatures) || selectedFeatures.length === 0) {
    console.error('Invalid or empty selectedFeatures array.');
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature && feature.geometry && feature.geometry.type !== 'Point'
  );

  if (filteredFeatures.length === 0) {
    console.warn('No valid features to display.');
    return;
  }

  const gasData = Array(168).fill(0);
  const electricityData = Array(168).fill(0);

  filteredFeatures.forEach((feature) => {
    if (feature.properties) {
      const gas = feature.properties["GasDemand_KWh"];
      const electricity = feature.properties["ElectricityDemand_KWh"];

      for (let hour = 0; hour < 168; hour++) {
        if (Array.isArray(gas) && gas.length > hour) {
          gasData[hour] += gas[hour] || 0;
        }
        if (Array.isArray(electricity) && electricity.length > hour) {
          electricityData[hour] += electricity[hour] || 0;
        }
      }
    } else {
      console.warn(`Feature with ID ${feature.id || 'unknown'} has no properties.`);
    }
  });

  const margin = { top: 20, right: 30, bottom: 60, left: 50 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 250) - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleLinear()
    .domain([0, 168])
    .range([0, width]);

  const maxYValue = d3.max([...gasData, ...electricityData]);
  if (maxYValue === undefined || maxYValue === null) {
    console.error('Failed to calculate maximum Y value.');
    return;
  }

  const y = d3.scaleLinear()
    .domain([0, maxYValue])
    .nice()
    .range([height, 0]);

  const lineGenerator = d3.line()
    .x((d, i) => x(i))
    .y(d => y(d));

  svg.append('path')
    .datum(electricityData)
    .attr('fill', 'none')
    .attr('stroke', '#1569C7')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  svg.append('path')
    .datum(gasData)
    .attr('fill', 'none')
    .attr('stroke', '#FFB100')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  const customXAxisTicks = [24, 48, 72, 96, 120, 144];
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).tickValues(customXAxisTicks));

  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

         // Y-axis label
  svg.append("text")
  .attr("class", "y-axis-label")
  .attr("x", -(height / 2))
  .attr("y", -margin.left + 15)
  .attr("text-anchor", "middle")
  .attr("transform", "rotate(-90)")
  .style("font-size", "10px")
  .text("kW");

       // Y-axis label
       svg.append("text")
       .attr("class", "x-axis-label")
       .attr("x", width )
       .attr("y", height+20)
       .attr("text-anchor", "middle")
       .style("font-size", "10px")
       .text("Hour");

  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 30})`);

  legend.append('rect')
    .attr('x', 0)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#1569C7');

  legend.append('text')
    .attr('x', 20)
    .attr('y', 5)
    .text('Electricity')
    .style('font-size', '10px')
    .style('fill', '#000');

  legend.append('rect')
    .attr('x', 100)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#FFB100');

  legend.append('text')
    .attr('x', 120)
    .attr('y', 5)
    .text('Gas')
    .style('font-size', '10px')
    .style('fill', '#000');
};
