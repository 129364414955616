import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  reasonableRoundUp,
  reasonableRoundDown,
} from "./Utilities";

// Tooltip component displays property values at specific screen coordinates (x, y)
// It appears when there are property values to show, otherwise it does not render
const Tooltip = ({ x, y, propertyValues }) => {
  // If there are no property values to display, return null to render nothing
  if (!propertyValues || propertyValues.length === 0) return null;

  // Render the Tooltip with a Paper component for a card-like appearance
  return (
    <Paper
      style={{
        position: 'absolute', // Positioning the tooltip absolutely within its container
        left: x, // Horizontal position based on the x prop
        top: y, // Vertical position based on the y prop
        padding: '10px', // Padding inside the tooltip for spacing
        pointerEvents: 'none', // Ensures the tooltip does not interfere with mouse events
        zIndex: 1000, // High z-index to ensure it appears above other elements
      }}
    >
      {/* Map over propertyValues array to display each property and its value */}
      {propertyValues.map(({ property, value }) => {
        // Check if the value is a number to determine whether to apply rounding
        let displayedValue = value;
        if (typeof value === 'number') {
          displayedValue = Math.round(value);
        }

        return (
          <Typography key={property} variant="body2"> {/* Use Typography for consistent text styling */}
            <strong>{property}:</strong> {displayedValue} {/* Display property name in bold and its value */}
          </Typography>
        );
      })}
    </Paper>
  );
};

export default Tooltip;
